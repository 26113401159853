import { isNumber } from 'lodash-es';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledBaseTypography = styled.p`
  margin: 0;
  color: ${props => props.color};
  font-weight: ${props => {
    if (isNumber(props.fontWeight)) {
      return props.fontWeight;
    }

    switch (props.fontWeight) {
      case 'medium':
        return '500';
      case 'semibold':
        return '600';
      case 'bold':
        return '700';
      case 'regular':
      default:
        return '400';
    }
  }};
  margin-bottom: 0px;
`;
