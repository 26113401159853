import { lazy } from 'react';

import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';
import { CommonLayout as CommonMobileLayout } from 'Layouts/Mobile';

const RegistrationRoutes = ({ isTabletOrMobile }) => {
  const Layout = isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout;
  const layoutProps = {
    desktop: {
      isFooterVisible: false,
      isLocationPickerVisible: false,
      isSearchFieldVisible: false,
      isNavbarWhatsappButtonVisible: false,
      isNavbarCartButtonVisible: false,
      isLoginButtonVisible: false,
    },
    mobile: {
      pageName: 'Buat Akun Baru',
      isWhatsappSectionVisible: false,
      isWhatsappButtonVisible: false,
      isNavbarCartButtonVisible: false,
      isWhatsappStickyButtonVisible: false,
    },
  };

  return [
    {
      path: '/register',
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-registration-mobile" */ 'Pages/Registration/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-registration-desktop" */ 'Pages/Registration/Desktop'
            )
          ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
  ];
};

export default RegistrationRoutes;
