import { lazy } from 'react';
import { CommonLayout as MobileCommonLayout } from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

/**
 * Home routes
 * @param {boolean} param.isTabletOrMobile
 * @returns {object[]}
 */
const Home = ({ isTabletOrMobile }) => [
  {
    path: '/categories',
    component: isTabletOrMobile
      ? lazy(() =>
          import(
            /* webpackChunkName: "page-categories-mobile" */ 'Pages/Categories/Mobile'
          )
        )
      : lazy(() =>
          import(
            /* webpackChunkName: "page-categories-desktop" */ 'Pages/Categories/Desktop'
          )
        ),
    layout: isTabletOrMobile ? MobileCommonLayout : CommonDesktopLayout,
    layoutProps: {
      ...(isTabletOrMobile ? { pageName: 'Semua Kategori' } : {}),
    },
  },
];

export default Home;
