import { lazy } from 'react';

import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';
import { CommonLayout as CommonMobileLayout } from 'Layouts/Mobile';

const PrivacyPolicyRoutes = ({ isTabletOrMobile }) => {
  const Layout = isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout;
  const layoutProps = {
    desktop: {
      isStickyWhatsappButtonVisible: false,
      isWhatsappSectionVisible: false,
    },
    mobile: {
      pageName: 'Privacy Policy',
      isWhatsappSectionVisible: false,
      isWhatsappStickyButtonVisible: false,
    },
  };

  return [
    {
      path: '/privacy-policy',
      component: lazy(() =>
        import(
          /* webpackChunkName: "page-privacy-policy" */ 'Pages/PrivacyPolicy'
        )
      ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
  ];
};

export default PrivacyPolicyRoutes;
