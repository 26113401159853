import { lazy } from 'react';
import { CommonLayout as CommonMobileLayout } from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

const AddressesRoutes = ({ isTabletOrMobile }) => {
  const layoutProps = {
    desktop: {
      isWhatsappSectionVisible: false,
      isFooterVisible: true,
      isStickyWhatsappButtonVisible: false,
    },
    mobile: {
      pageName: 'Daftar Alamat',
      isWhatsappSectionVisible: false,
      isNavbarCartButtonVisible: false,
      isWhatsappStickyButtonVisible: false,
    },
  };

  return [
    {
      path: '/user/addresses',
      isPrivate: true,
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-user-addresses-mobile" */ 'Pages/Addresses/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-user-addresses-desktop" */ 'Pages/Addresses/Desktop'
            )
          ),
      layout: isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout,
      layoutProps: isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop,
    },
  ];
};

export default AddressesRoutes;
