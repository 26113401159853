import { lazy } from 'react';

import { ProfileLayout } from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

// const PageNotExist = () => <div>Page Not Exist</div>;

const OrderRoutes = ({ isTabletOrMobile }) => {
  const Layout = isTabletOrMobile ? ProfileLayout : CommonDesktopLayout;
  const layoutProps = {
    mobile: {
      pageName: 'Detail Pesanan',
      isWhatsappSectionVisible: false,
      useBottomGutter: false,
    },
    desktop: { isFooterVisible: false },
  };

  return [
    {
      path: '/orders',
      isPrivate: true,
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-order-history-mobile" */ 'Pages/OrderHistory/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-order-history-desktop" */ 'Pages/OrderHistory/Desktop'
            )
          ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
    {
      path: '/orders/:salesOrderNumber',
      isPrivate: true,
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-order-history-detail-mobile" */ 'Pages/OrderHistoryDetail/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-order-history-detail-desktop" */ 'Pages/OrderHistoryDetail/Desktop'
            )
          ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
  ];
};

export default OrderRoutes;
