import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Container from 'Components/Globals/Container';
import { COLOR } from 'Configurations/Constants/styles';

export const StyledWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
`;

export const StyledFooterContent = styled.div`
  background-color: ${COLOR.GRAY_05};
  color: ${COLOR.GRAY_100};
  padding: 24px;
  margin-top: 24px;
`;

export const StyledLogoWrapper = styled.div`
  text-align: center;
`;

export const StyledWhatsappSection = styled.div`
  background-color: white;
  padding: 40px;
`;

export const StyledWhatsappSectionContent = styled(Container)`
  background-color: #fafafa;
  padding: 20px;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${COLOR.GRAY_100};

  &:hover {
    color: ${COLOR.ORANGE_60};
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
  color: ${COLOR.GRAY_100};
  display: inline-block;

  &:hover {
    color: ${COLOR.ORANGE_60};
  }
`;

export const StyledContactInfo = styled.div`
  margin-bottom: 18px;
  margin-bottom: 18px;
  display: flex;
`;
