import { lazy } from 'react';
import { HomeLayout } from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

/**
 * Home routes
 * @param {boolean} param.isTabletOrMobile
 * @returns {object[]}
 */
const Home = ({ isTabletOrMobile }) => {
  const Layout = isTabletOrMobile ? HomeLayout : CommonDesktopLayout;
  const layoutProps = {
    desktop: {},
    mobile: { shouldDisplayBottomNavbar: true, paddingBottom: 122 },
  };

  return [
    {
      path: '/',
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-home-mobile" */ 'Pages/Home/Mobile'
            )
          )
        : lazy(() =>
            import(/* webpackChunkName: "page-home-desktop" */ 'Pages/Home/Desktop')
          ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
  ];
};

export default Home;
