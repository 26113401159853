import Cookies from 'js-cookie';

const options = {
  samesite: 'strict',
  'max-age': '604800', // 7 days (in seconds)
};

const COOKIE_KEY = 'mtreeauth';

export function setAuthCookie(authData) {
  const { companyId, name, token, role, roleRights } = authData;

  const data = JSON.stringify({
    companyId,
    name,
    token,
    role,
    roleRights,
  });

  Cookies.set(COOKIE_KEY, data, options);
}

export function getAuthCookie() {
  const data = Cookies.get(COOKIE_KEY);

  try {
    return JSON.parse(data);
  } catch (err) {
    return undefined;
  }
}

export function clearAuthCookie() {
  Cookies.remove(COOKIE_KEY);
}
