import styled, { css } from 'styled-components';
import { Badge } from 'antd';
import { COLOR, Z_INDEX_MOBILE_NAVBAR } from 'Configurations/Constants/styles';

export const StyledNavbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 52px;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  z-index: ${Z_INDEX_MOBILE_NAVBAR};

  ${props => {
    switch (props.variant) {
      case 'primary':
        return css`
          background-color: #f37021;
        `;
      default:
        return css`
          background-color: #fff;
          border-bottom: 1px solid #eaeaea;
        `;
    }
  }};

  ${props =>
    props.hasBottomComponent &&
    css`
      padding-bottom: 0;
    `}
`;

export const StyledUpperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBackButton = styled.div`
  cursor: pointer;
`;

export const StyledPageHeadingContainer = styled.div`
  width: 100%;
`;

export const StyledCartButton = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 16px;
`;

export const StyledGutter = styled.div`
  height: 52px;
  background: white;
`;

export const StyledBadge = styled(Badge)`
  margin-top: 2px;
  margin-right: 4px;

  .ant-badge-count {
    color: ${props => props.textColor || COLOR.WHITE};
    background: ${props => props.backgroundColor || COLOR.WHITE};
    font-weight: 700;
  }
`;
