import { Button, Badge, Avatar } from 'antd';
import styled, { css } from 'styled-components';

import { Z_INDEX, COLOR } from 'Configurations/Constants/styles';
import Typography from 'Components/Globals/Typography';

export const StyledNavbarContainer = styled.div`
  background-color: #f37021;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  z-index: ${Z_INDEX.NAVBAR};
`;

export const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background: white;
    color: #f37021;
    font-weight: 700;
  }
`;

export const StyledProfileMenu = styled.div`
  display: flex;
`;

export const StyledProfileName = styled(Typography.Body14)`
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const StyledProfileButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${COLOR.WHITE};
  padding: 8px;
  height: 48px;
  border-radius: 8px;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    color: ${COLOR.WHITE};
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

export const StyledMenuHeader = styled.div``;

export const StyledAvatar = styled(Avatar)`
  ${props => {
    if (props.variant === 'white') {
      return css`
        background-color: ${COLOR.WHITE};
        color: ${COLOR.BLACK};
      `;
    }

    return css`
      background-color: ${COLOR.ORANGE_60};
      color: ${COLOR.WHITE};
    `;
  }}

  vertical-align: 'middle';
  margin-right: 8px;
`;

export const StyledVerticalDivider = styled.div`
  width: 1px;
  height: 32px;
  border-left: 1px solid white;
  margin-right: 16px;
`;
