import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLOR } from 'Configurations/Constants/styles';
import { StyledBaseTypography } from './Typography.style';

const BaseTypography = ({
  className,
  fontWeight,
  htmlTag,
  children,
  ...rests
}) => (
  <StyledBaseTypography
    className={className}
    fontWeight={fontWeight}
    as={htmlTag}
    {...rests}
  >
    {children}
  </StyledBaseTypography>
);

BaseTypography.propTypes = {
  fontWeight: PropTypes.oneOf(['regular', 'medium', 'bold', PropTypes.number]),
  htmlTag: PropTypes.string,
  color: PropTypes.string,
};

BaseTypography.defaultProps = {
  fontWeight: 'regular',
  htmlTag: 'p',
  color: COLOR.GRAY_100,
};

/** ===============
 * Public API. Based on https://www.figma.com/file/NVVdUJ1vJjD1a4nHeTWJEp/Materee?node-id=191%3A2144
 * ===============
 * */

export const Caption08 = styled(BaseTypography)`
  font-size: 8px;
  line-height: 12px;
`;

export const Caption10 = styled(BaseTypography)`
  font-size: 10px;
  line-height: 12px;
`;

export const Caption12 = styled(BaseTypography)`
  font-size: 12px;
  line-height: 16px;
`;

export const Body14 = styled(BaseTypography)`
  font-size: 14px;
  line-height: 20px;
`;

export const BodyLong14 = styled(BaseTypography)`
  font-size: 14px;
  line-height: 22px;
`;

export const Body16 = styled(BaseTypography)`
  font-size: 16px;
  line-height: 20px;
`;

export const BodyLong16 = styled(BaseTypography)`
  font-size: 16px;
  line-height: 22px;
`;

export const BodyLong20 = styled(BaseTypography)`
  font-size: 20px;
  line-height: 28px;
`;

export const Subheading18 = styled(BaseTypography)`
  font-size: 18px;
  line-height: 22px;
`;

export const Subheading20 = styled(BaseTypography)`
  font-size: 20px;
  line-height: 26px;
`;

export const Heading24 = styled(BaseTypography)`
  font-size: 24px;
  line-height: 28px;
`;

export const Heading28 = styled(BaseTypography)`
  font-size: 28px;
  line-height: 34px;
`;

export const Heading32 = styled(BaseTypography)`
  font-size: 32px;
  line-height: 36px;
`;

export const Heading42 = styled(BaseTypography)`
  font-size: 42px;
  line-height: 48px;
`;
