import { getAuthCookie } from 'Configurations/Utilities/cookiesAuth';

let initialAuthState = {
  isLogin: false,
  data: null,
};

const savedAuthCookie = getAuthCookie();

if (savedAuthCookie) {
  initialAuthState = {
    isLogin: true,
    data: { ...savedAuthCookie },
  };
}

const auth = {
  state: { ...initialAuthState },

  reducers: {
    setLogin(state, data = null) {
      return {
        isLogin: true,
        data,
      };
    },

    setLogout() {
      return {
        isLogin: false,
        data: null,
      };
    },
  },
};

export default auth;
