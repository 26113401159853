/**
 * @todo - rename this hook to `useCart.jsx` later if all cart system has been refactored to use this particular hook
 */
import { useMemo } from 'react';
import { get } from 'lodash-es';
import useAuth from 'Hooks/useAuth';
import {
  useQueryGetCart,
  useMutationUpdateCart,
  useMutationDeleteCart,
} from 'Hooks/Api/cart';

const useCartNew = () => {
  const { isLogin } = useAuth();
  const {
    cartData: cartDataRaw,
    isCartLoading,
    refetchCart,
  } = useQueryGetCart({
    enabled: isLogin,
  });
  const { updateCartMutation, isUpdateCartLoading } = useMutationUpdateCart({
    onSuccess: refetchCart,
  });
  const { deleteCartMutation, isDeleteCartLoading } = useMutationDeleteCart({
    onSuccess: refetchCart,
  });

  /**
   * Get the actual `content` from the GET API response data
   * @returns {object[]}
   */
  const cartData = useMemo(
    () => get(cartDataRaw, 'content', []),
    [cartDataRaw]
  );

  /**
   * @returns {boolean}
   */
  const isCartEmpty = useMemo(() => !!get(cartData, 'length') > 0, [cartData]);

  /**
   * A computed property which displays the amount of products in the cart
   * @returns {number}
   */
  const totalProductsInCart = useMemo(
    () =>
      cartData.reduce(
        (total, cartItem) => total + get(cartItem, 'quantity'),
        0
      ),
    [cartData]
  );

  return {
    cartData,
    isCartEmpty,
    totalProductsInCart,
    isCartLoading,

    updateCartMutation,
    isUpdateCartLoading,

    deleteCartMutation,
    isDeleteCartLoading,
  };
};

export default useCartNew;
