const DEFAULT_STATE = {
  selectedProductIds: [], // stores selected cart items
};

/** Available states for the cart module */
const modelState = { ...DEFAULT_STATE };

/** Available methods for mutating the cart module states */
const reducers = {
  /**
   * Select particular item in the cart
   * @param {DEFAULT_STATE} state
   * @param {object} payload
   * @param {number} payload.productId
   * @returns {DEFAULT_STATE}
   */
  selectCartItem(state, { productId }) {
    const { selectedProductIds } = state;
    const newSelectedCartItems = [...selectedProductIds, productId];

    return {
      ...state,
      selectedProductIds: [...newSelectedCartItems],
    };
  },

  /**
   * Remove a selected item from the selected cart items array
   * @param {DEFAULT_STATE} state
   * @param {object} payload
   * @param {number} payload.productId
   * @returns {DEFAULT_STATE}
   */
  removeSelectedCartItem(state, { productId }) {
    const { selectedProductIds } = state;
    const filteredResult = selectedProductIds.filter(
      item => item !== productId
    );

    return {
      ...state,
      selectedProductIds: [...filteredResult],
    };
  },

  /**
   * Remove all selected cart items
   * @param {DEFAULT_STATE} state
   * @returns {DEFAULT_STATE}
   */
  removeAllSelectedCartItem(state) {
    return {
      ...state,
      selectedProductIds: [],
    };
  },
};

const cartModel = {
  state: { ...modelState },
  reducers,
};

export default cartModel;
