import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export const StyledFilterItem = styled.div`
  border: 1px solid #dadada;
  border-radius: 4px;
  padding: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  width: 100%;

  ${props => {
    switch (props.variant) {
      case 'product-category':
        return css`
          min-width: 124px;
        `;
      case 'product-sub-category':
        return css`
          min-width: 100px;
        `;
      default:
        return '';
    }
  }}

  ${props =>
    props.selected &&
    css`
      background-color: #fff4ee;
      border-color: #f37021;
    `}
`;

export const StyledFilterValueText = styled(Typography)`
  font-size: 12px;

  ${props =>
    props.selected &&
    css`
      color: #f37021;
    `}
`;

export const StyledDownOutlined = styled(DownOutlined)`
  font-size: 10px;
  margin-left: 8px;

  ${props =>
    props.selected &&
    css`
      color: #f37021;
    `}
`;
