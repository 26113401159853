import React from 'react';
import { Layout as MainLayout } from 'antd';
import { COLOR } from 'Configurations/Constants/styles';
import BottomNavbar from 'Components/Mobile/BottomNavbar';

const { Content } = MainLayout;

const ProfileLayout = ({ children }) => (
  <MainLayout style={{ height: '100%' }}>
    <Content style={{ backgroundColor: COLOR.WHITE }}>{children}</Content>
    <BottomNavbar />
  </MainLayout>
);

export default ProfileLayout;
