import React from 'react';
import PropTypes from 'prop-types';

import { COSTUMER_SERVICE_WHATSAPP } from 'Configurations/Constants/config';
import contactWhatsapp from 'Configurations/Utilities/contactWhatsapp';

import useTracker from 'Hooks/useTracker';

import { StyledButton, StyledWhatsappIcon } from './StickyWhatsappButton.style';

const StickyWhatsappButton = ({ marginBottom }) => {
  const { trackWhatsappButtonClick } = useTracker();

  const openWhatsapp = () => {
    const whatsappMessage =
      'Halo Tim Materee, saya ingin bertanya lebih lanjut.';

    contactWhatsapp(COSTUMER_SERVICE_WHATSAPP, whatsappMessage);

    trackWhatsappButtonClick({ location: 'Sticky Whatsapp Button' });
  };

  return (
    <StyledButton onClick={openWhatsapp} marginBottom={marginBottom}>
      <StyledWhatsappIcon />
    </StyledButton>
  );
};

StickyWhatsappButton.propTypes = {
  marginBottom: PropTypes.number,
};

StickyWhatsappButton.defaultProps = {
  marginBottom: 12,
};

export default StickyWhatsappButton;
