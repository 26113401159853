import styled from 'styled-components';
import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import { Z_INDEX_MOBILE_NAVBAR } from 'Configurations/Constants/styles';

const { Footer } = Layout;

export const StyledNavbarContainer = styled(Footer)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 8px 16px;
  z-index: ${Z_INDEX_MOBILE_NAVBAR};
`;

export const StyledMenuLink = styled(NavLink)`
  cursor: pointer;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #000;
  }
`;
