import React from 'react';
import { Layout as MainLayout, Image } from 'antd';
import Logo from 'Static/Logo-white.svg';
import { COLOR } from 'Configurations/Constants/styles';

const { Content, Header } = MainLayout;

const SalesOrderLayout = ({ children }) => (
  <MainLayout style={{ height: '100%' }}>
    <Header
      style={{
        background: COLOR.PRIMARY,
        padding: '0px 24px',
      }}
    >
      <Image
        preview={false}
        src={Logo}
        alt="Materee.id"
        height={32}
        width={120}
        style={{ marginBottom: 8, marginTop: 8, cursor: 'pointer' }}
      />
    </Header>
    <Content style={{ backgroundColor: COLOR.WHITE, padding: '24px 0' }}>
      {children}
    </Content>
  </MainLayout>
);

export default SalesOrderLayout;
