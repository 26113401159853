import React from 'react';
import { Button, Image } from 'antd';

import { COSTUMER_SERVICE_WHATSAPP } from 'Configurations/Constants/config';
import contactWhatsapp from 'Configurations/Utilities/contactWhatsapp';
import Typography from 'Components/Globals/Typography';

import WhatsappFilled from 'Static/Icons/whatsapp-filled.svg';

import {
  StyledWhatsappSection,
  StyledWhatsappSectionContent,
} from './WhatsappSection.style';

const WhatsappSection = () => (
  <StyledWhatsappSection>
    <StyledWhatsappSectionContent>
      <Typography.Body14 fontWeight="bold">
        Mau cari apa di Materee?
      </Typography.Body14>

      <Typography.Caption12 style={{ margin: '6px 0' }}>
        Tim Materee selalu siap untuk menjawab setiap <br />
        pertanyaan anda
      </Typography.Caption12>

      <Button
        onClick={() => contactWhatsapp(COSTUMER_SERVICE_WHATSAPP)}
        style={{ marginTop: 16 }}
      >
        <span style={{ marginRight: 6 }}>
          <Image src={WhatsappFilled} preview={false} />
        </span>
        Hubungi Whatsapp
      </Button>
    </StyledWhatsappSectionContent>
  </StyledWhatsappSection>
);

export default WhatsappSection;
