import { lazy } from 'react';
import {
  ProductListLayout as MobileProductListLayout,
  CommonLayout as CommonMobileLayout,
} from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

/**
 * Product routes
 * @param {boolean} params.isTabletOrMobile
 * @returns {object[]}
 */
const Products = ({ isTabletOrMobile }) => {
  const layoutProps = {
    productDetail: {
      desktop: { isWhatsappSectionVisible: false },
      mobile: {
        pageName: 'Product Detail',
      },
    },
  };

  return [
    {
      path: '/products',
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-product-list-mobile" */ 'Pages/Product/ProductList/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-product-list-desktop" */ 'Pages/Product/ProductList/Desktop'
            )
          ),
      layout: isTabletOrMobile ? MobileProductListLayout : CommonDesktopLayout,
      layoutProps: {
        ...(isTabletOrMobile ? {} : { isWhatsappSectionVisible: false }),
      },
    },
    {
      path: '/products/:slug',
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-product-detail-mobile" */ 'Pages/Product/ProductDetail/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-product-detail-desktop" */ 'Pages/Product/ProductDetail/Desktop'
            )
          ),
      layout: isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout,
      layoutProps: {
        ...(isTabletOrMobile
          ? layoutProps.productDetail.mobile
          : layoutProps.productDetail.desktop),
      },
    },
  ];
};

export default Products;
