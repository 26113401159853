import { useContext } from 'react';
import { ProductSearchContext } from 'Contexts/ProductSearch';

const useProductSearch = () => {
  const context = useContext(ProductSearchContext);
  if (context === undefined) {
    throw new Error(
      'useProductSearch must be used within ProductSearchProvider'
    );
  }

  const {
    // Search query, text typed by user in searchbar
    searchQuery,
    setSearchQuery,

    // consist of selected filters
    filters,
    populateFilters,
    unpopulateFilters,
    selectedFilters,
    applyFilter,
    unapplyFilter,

    // Filter drawer states and methos
    activeFilter,
    toggleFilterDrawer,
  } = context;

  return {
    // Search query, text typed by user in searchbar
    searchQuery,
    setSearchQuery,

    // consist of selected filters
    filters,
    populateFilters,
    unpopulateFilters,
    selectedFilters,
    applyFilter,
    unapplyFilter,

    // Filter drawer states and methos
    activeFilter,
    toggleFilterDrawer,
  };
};

export default useProductSearch;
