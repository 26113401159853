import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Divider, Button, Spin } from 'antd';
import {
  WhatsAppOutlined,
  ArrowRightOutlined,
  MailOutlined,
} from '@ant-design/icons';

import {
  COSTUMER_SERVICE_WHATSAPP,
  CUSTOMER_SERVICE_WHATSAPP_FORMATTED,
  CUSTOMER_SERVICE_WHATSAPP_INDONESIAN_FORMAT,
  GOOGLE_MAP_LOCATION,
  GOOGLE_PLAYSTORE_LINK,
} from 'Configurations/Constants/config';
import contactWhatsapp from 'Configurations/Utilities/contactWhatsapp';

import { useQueryGetAllCategory } from 'Hooks/Api/categories';
import useTracker from 'Hooks/useTracker';

import Container from 'Components/Globals/Container';
import Typography from 'Components/Globals/Typography';
import CompanyMapAvif from 'Static/new-company-map.avif';
import CompanyMapPng from 'Static/new-company-map.png';
import CompanyMapWebp from 'Static/new-company-map.webp';
import PlayStoreImage from 'Static/download-app-playstore.png';
import {
  StyledWrapper,
  StyledFooterContent,
  StyledWhatsappSection,
  StyledWhatsappSectionContent,
  StyledLogoWrapper,
  StyledLink,
  StyledAnchor,
  StyledContactInfo,
} from './Footer.style';

const Footer = ({ isWhatsappSectionVisible }) => {
  const { isLoading: isLoadingCategories, categories } =
    useQueryGetAllCategory();
  const { trackWhatsappButtonClick } = useTracker();

  const footerHeightInPx = 200;

  const todaysYear = new Date().getFullYear();

  const openWhatsappLink = () => {
    const whatsappMessage =
      'Halo Tim Materee, saya ingin bertanya lebih lanjut.';

    contactWhatsapp(COSTUMER_SERVICE_WHATSAPP, whatsappMessage);

    trackWhatsappButtonClick({
      location: 'Hubungi Materee Whatsapp - Footer',
    });
  };

  return (
    <>
      <div style={{ height: footerHeightInPx }} />

      <StyledWrapper style={{ height: footerHeightInPx }}>
        {isWhatsappSectionVisible && (
          <StyledWhatsappSection>
            <StyledWhatsappSectionContent>
              <Typography.Subheading20 style={{ marginBottom: 20 }}>
                Tim Materee selalu siap untuk menjawab setiap <br />
                pertanyaan anda
              </Typography.Subheading20>

              <Button icon={<WhatsAppOutlined />} onClick={openWhatsappLink}>
                Hubungi Whatsapp
              </Button>
            </StyledWhatsappSectionContent>
          </StyledWhatsappSection>
        )}

        <Container>
          <Divider style={{ margin: '40px 0' }} />

          <Typography.Heading24 fontWeight="bold" style={{ marginBottom: 24 }}>
            Kategori Produk
          </Typography.Heading24>

          <Spin spinning={isLoadingCategories}>
            <Row>
              <Col span={20}>
                <Row>
                  {categories.map(category => (
                    <Col span={8} key={category.id} style={{ marginBottom: 6 }}>
                      <StyledLink to={`/products?category=${category.id}`}>
                        <Typography.BodyLong14>
                          {category.name}
                        </Typography.BodyLong14>
                      </StyledLink>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Spin>

          <Divider style={{ margin: '40px 0' }} />

          <Row>
            <Col span={8}>
              <Typography.Heading24
                fontWeight="bold"
                style={{ marginBottom: 16 }}
              >
                Hubungi Materee
              </Typography.Heading24>

              <StyledContactInfo>
                <WhatsAppOutlined
                  style={{
                    marginRight: 8,
                    marginTop: 4,
                    fontSize: 20,
                  }}
                />

                <div
                  role="button"
                  tabIndex="0"
                  onKeyDown={openWhatsappLink}
                  onClick={openWhatsappLink}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography.Body14>Whatsapp</Typography.Body14>
                  <Typography.Body14 fontWeight="bold">
                    {CUSTOMER_SERVICE_WHATSAPP_FORMATTED}
                  </Typography.Body14>
                </div>
              </StyledContactInfo>

              <StyledContactInfo>
                <MailOutlined
                  style={{
                    marginRight: 8,
                    marginTop: 4,
                    fontSize: 20,
                  }}
                />

                <div>
                  <Typography.Body14>Email</Typography.Body14>
                  <Typography.Body14 fontWeight="bold">
                    info@materee.id
                  </Typography.Body14>
                </div>
              </StyledContactInfo>

              <Typography.Heading24
                fontWeight="bold"
                style={{ marginBottom: 8, marginTop: 40 }}
              >
                Materee Headquarter
              </Typography.Heading24>

              <Typography.Body14>
                The City Tower Lt 12 Unit 1N, Jl. MH. Thamrin Nomor 81,
                Desa/Kelurahan Menteng, Kec. Menteng, Jakarta Pusat, DKI
                Jakarta, Indonesia, 10310
              </Typography.Body14>

              <Typography.Heading24
                fontWeight="bold"
                style={{ marginBottom: 8, marginTop: 40 }}
              >
                Download Aplikasi Materee
              </Typography.Heading24>

              <a
                href={GOOGLE_PLAYSTORE_LINK}
                target="_blank"
                rel="noopener noreferrer"
                title="Download Materee di Google Play Store"
              >
                <img
                  src={PlayStoreImage}
                  alt="Download App from Google Play Store"
                  width={132}
                  height={40}
                />
              </a>
            </Col>
            <Col offset={4} span={12}>
              <Typography.Heading24
                fontWeight="bold"
                style={{ marginBottom: 8 }}
              >
                Lokasi
              </Typography.Heading24>

              <a
                href={GOOGLE_MAP_LOCATION}
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source srcSet={CompanyMapAvif} type="image/webp" />
                  <source srcSet={CompanyMapWebp} type="image/webp" />
                  <img
                    src={CompanyMapPng}
                    alt="Materee Location"
                    width="100%"
                  />
                </picture>
              </a>

              <StyledAnchor
                href={GOOGLE_MAP_LOCATION}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginTop: 8 }}
              >
                <Typography.Body14 fontWeight="bold">
                  Lihat di google maps <ArrowRightOutlined />
                </Typography.Body14>
              </StyledAnchor>
            </Col>
            <Col span={13} offset={11}>
              <Typography.Heading24 fontWeight="bold">
                Layanan Pengaduan Konsumen
              </Typography.Heading24>
            </Col>
            <Col span={6} offset={11}>
              <Typography.Body14>
                PT. Materee Nusantara Utama
                <br />
                <br />
                <a href="mailto:info@materee.id" style={{ color: '#333333' }}>
                  info@materee.id
                </a>{' '}
                / {CUSTOMER_SERVICE_WHATSAPP_INDONESIAN_FORMAT} (WhatsApp)
              </Typography.Body14>
            </Col>
            <Col span={7}>
              Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga
              Kementerian Perdagangan Republik Indonesia 0853 1111 1010
              (WhatsApp)
            </Col>
          </Row>
        </Container>

        <StyledFooterContent>
          <Container>
            <StyledLogoWrapper>
              <Typography.Body14 htmlTag="span">
                PT Materee Nusantara Utama &copy; {todaysYear}
              </Typography.Body14>
            </StyledLogoWrapper>
          </Container>
        </StyledFooterContent>
      </StyledWrapper>
    </>
  );
};

Footer.propTypes = {
  /** When set to `true`, the Whatsapp section will appears */
  isWhatsappSectionVisible: PropTypes.bool,
};

Footer.defaultProps = {
  isWhatsappSectionVisible: false,
};

export default Footer;
