import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SearchOutlined,
  ArrowLeftOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Form, Input, Col, Row, Image } from 'antd';

import { COLOR } from 'Configurations/Constants/styles';
import { COSTUMER_SERVICE_WHATSAPP } from 'Configurations/Constants/config';
import contactWhatsapp from 'Configurations/Utilities/contactWhatsapp';

import Typography from 'Components/Globals/Typography';

import WhatsappFilledWhite from 'Static/Icons/whatsapp-filled-white.svg';
import WhatsappFilled from 'Static/Icons/whatsapp-filled.svg';

import useProductSearch from 'Hooks/useProductSearch';
import useTracker from 'Hooks/useTracker';
import useCartNew from 'Hooks/useCartNew';

import {
  StyledNavbarContainer,
  StyledGutter,
  StyledBadge,
} from './Navbar.style';

const Navbar = ({
  variant,
  isSearchBarVisible,
  isBackButtonVisible,
  pageName,
  bottomComponent,
  useGutter,
  onInputFocus,
  isWhatsappButtonVisible,
  isNavbarCartButtonVisible,
}) => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const navbarContainer = useRef(null);

  const { searchQuery } = useProductSearch();
  const { trackWhatsappButtonClick } = useTracker();
  const { totalProductsInCart } = useCartNew();

  const openWhatsapp = () => {
    contactWhatsapp(COSTUMER_SERVICE_WHATSAPP);

    trackWhatsappButtonClick({
      location: 'Whatsapp Icon - Navbar Mobile',
    });
  };

  const goToCartPage = () => {
    if (location.pathname !== '/cart') {
      history.push('/cart');
    }
  };

  /**
   * @effect
   * Dynamically set navbar height on initial render
   */
  useEffect(() => {
    setNavbarHeight(navbarContainer.current.clientHeight);
  }, []);

  /**
   * @effect
   * make sure the value typed by user can be properly shown
   */
  useEffect(() => {
    form.setFieldsValue({ 'search-query': searchQuery });
  }, [searchQuery]);

  return (
    <>
      <StyledNavbarContainer
        ref={navbarContainer}
        variant={variant}
        hasBottomComponent={!!bottomComponent}
      >
        <Row justify="space-between" align="middle" style={{ height: '100%' }}>
          {/* Back Button */}
          {isBackButtonVisible && (
            <Col
              flex="36px"
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <ArrowLeftOutlined
                style={{ fontSize: 18 }}
                onClick={() => history.goBack()}
              />
            </Col>
          )}
          {/* Searchbar */}
          {isSearchBarVisible && (
            <Col flex="auto" style={{ paddingRight: 12 }}>
              <Form form={form}>
                <Form.Item name="search-query" style={{ marginBottom: 0 }}>
                  <Input
                    placeholder="Cari produk material"
                    suffix={
                      <SearchOutlined
                        style={{ fontSize: 16, color: '#d9d9d9' }}
                      />
                    }
                    onFocus={onInputFocus}
                    style={{ fontSize: 14 }}
                  />
                </Form.Item>
              </Form>
            </Col>
          )}
          {/* Page Name */}
          {!isSearchBarVisible && pageName && (
            <Col flex="auto">
              <Typography.Subheading18>{pageName}</Typography.Subheading18>
            </Col>
          )}

          {/* Whatsapp Button, Primary */}
          {isWhatsappButtonVisible && variant === 'primary' && (
            <Col
              flex="22px"
              style={{ display: 'flex', alignItems: 'center', marginLeft: 16 }}
            >
              <span
                style={{ width: 22, height: 22, cursor: 'pointer' }}
                role="button"
                tabIndex="0"
                onKeyDown={openWhatsapp}
                onClick={openWhatsapp}
              >
                <Image
                  src={WhatsappFilledWhite}
                  preview={false}
                  style={{ width: 22 }}
                />
              </span>
            </Col>
          )}

          {/* Whatsapp Button, Secondary */}
          {isWhatsappButtonVisible && variant === 'secondary' && (
            <Col
              flex="22px"
              style={{ display: 'flex', alignItems: 'center', marginLeft: 16 }}
            >
              <div
                style={{ width: 22, height: 22, cursor: 'pointer' }}
                role="button"
                tabIndex="0"
                onKeyDown={openWhatsapp}
                onClick={openWhatsapp}
              >
                <Image
                  src={WhatsappFilled}
                  preview={false}
                  style={{ width: 22 }}
                />
              </div>
            </Col>
          )}

          {/** Cart Button */}
          {isNavbarCartButtonVisible && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={goToCartPage}
              onKeyDown={goToCartPage}
              tabIndex="0"
              role="button"
            >
              <StyledBadge
                count={totalProductsInCart}
                showZero
                textColor={
                  variant === 'primary' ? COLOR.ORANGE_60 : COLOR.WHITE
                }
                backgroundColor={
                  variant === 'primary' ? COLOR.WHITE : COLOR.ORANGE_60
                }
              >
                <ShoppingCartOutlined
                  style={{
                    fontSize: 24,
                    color: variant === 'primary' ? '#fff' : '#000',
                  }}
                />
              </StyledBadge>
            </div>
          )}
        </Row>

        {/* Bottom Component */}
        {/* Can be LocationPicker or SearchFilters */}
        <Row align="center">
          <Col span={24}>{bottomComponent}</Col>
        </Row>
      </StyledNavbarContainer>

      {useGutter && <StyledGutter style={{ height: navbarHeight }} />}
    </>
  );
};

Navbar.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  isSearchBarVisible: PropTypes.bool.isRequired,
  isBackButtonVisible: PropTypes.bool.isRequired,
  pageName: PropTypes.string,
  bottomComponent: PropTypes.element,

  /** When set to `true`, a space (gutter) will be exist and it makes the navbar has "margin bottom effect" */
  useGutter: PropTypes.bool,

  /** Function to be invoked when the search field is focused on */
  onInputFocus: PropTypes.func,

  /** Decides to show/hide whatsapp button */
  isWhatsappButtonVisible: PropTypes.bool,

  /** Decides to show/hide cart button */
  isNavbarCartButtonVisible: PropTypes.bool,
};

Navbar.defaultProps = {
  pageName: '',
  bottomComponent: null,
  useGutter: true,
  onInputFocus: () => {},
  isWhatsappButtonVisible: false,
  isNavbarCartButtonVisible: true,
};

export default Navbar;
