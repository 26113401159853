import React from 'react';
import PropTypes from 'prop-types';

import Navbar from 'Components/Mobile/Navbar';
import MobileStickyWhatsappButton from 'Components/StickyWhatsappButton';
import WhatsappSection from 'Components/Mobile/WhatsappSection';

const CommonLayout = ({
  pageName,
  children,
  isWhatsappSectionVisible,
  isWhatsappStickyButtonVisible,
  isWhatsappButtonVisible,
  isNavbarCartButtonVisible,
  useBottomGutter,
}) => (
  <div>
    <Navbar
      variant="secondary"
      isBackButtonVisible
      isSearchBarVisible={false}
      isWhatsappButtonVisible={isWhatsappButtonVisible}
      isNavbarCartButtonVisible={isNavbarCartButtonVisible}
      pageName={pageName}
    />

    {children}

    {isWhatsappStickyButtonVisible && (
      <MobileStickyWhatsappButton marginBottom={useBottomGutter ? 84 : 24} />
    )}

    {isWhatsappSectionVisible && <WhatsappSection />}

    {useBottomGutter && <div style={{ height: 80, width: '100%' }} />}
  </div>
);

CommonLayout.propTypes = {
  /** Set the page name */
  pageName: PropTypes.string,

  /** When set to `true`, the section whatsapp will be visible */
  isWhatsappSectionVisible: PropTypes.bool,

  /** When set to `true`, the sticky whatsapp button will be visible */
  isWhatsappStickyButtonVisible: PropTypes.bool,

  /** When set to `false`, the whatsapp icon on navbar will be hidden */
  isWhatsappButtonVisible: PropTypes.bool,

  /** When set to `false`, the cart icon on navbar will be hidden */
  isNavbarCartButtonVisible: PropTypes.bool,

  /** When set to `true`, a space will be exist at the bottom */
  useBottomGutter: PropTypes.bool,
};

CommonLayout.defaultProps = {
  pageName: '',
  isWhatsappSectionVisible: true,
  isWhatsappStickyButtonVisible: true,
  isWhatsappButtonVisible: false,
  isNavbarCartButtonVisible: true,
  useBottomGutter: true,
};

export default CommonLayout;
