import React, { useMemo, useEffect, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import useDevice from 'Hooks/useDevice';
import useCart from 'Hooks/useCart';
import useScroll from 'Hooks/useScroll';
import useAuth from 'Hooks/useAuth';

import Typography from 'Components/Globals/Typography';
import LoadingPageIndicator from 'Components/LoadingPageIndicator';
import ProductSearchProvider from 'Contexts/ProductSearch';
import TrackerProvider from 'Contexts/Tracker';
import WhatsappRedirect from 'Pages/WhatsappRedirect';

import HomeRoutes from './Home';
import Products from './Products';
import CategoriesRoutes from './Categories';
import OrderConfirmation from './OrderConfirmation';
import Cart from './Cart';
import RegistrationRoutes from './RegistrationRoutes';
import LoginRoutes from './LoginRoutes';
import ProfileRoutes from './ProfileRoutes';
import OrderRoutes from './OrderRoutes';
import PrivacyPolicyRoutes from './PrivacyPolicyRoutes';
import SalesOrderRoutes from './SalesOrder';
import AddressesRoutes from './AddressesRoutes';
import CheckoutRoutes from './CheckoutRoutes';

const RestrictedPage = () => (
  <div style={{ padding: 16, marginTop: 128 }}>
    <LockOutlined
      style={{ marginBottom: 26, fontSize: 58, color: '#A0A0A0' }}
    />

    <Typography.Subheading20>
      Halaman hanya tersedia untuk pelanggan terdaftar
    </Typography.Subheading20>

    <Typography.Body14 style={{ marginTop: 8, marginBottom: 16 }}>
      Daftar dengan hubungi tim Materee
    </Typography.Body14>

    <Button type="primary">Daftar ke Materee</Button>
  </div>
);

const Routes = () => {
  const location = useLocation();
  const { scrollToTop } = useScroll();
  const { isTabletOrMobile } = useDevice();
  const { isCartEmpty } = useCart();
  const { isLogin } = useAuth();

  // Scroll to top if path changes
  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  const routes = useMemo(
    () => [
      ...HomeRoutes({ isTabletOrMobile }),
      ...Products({ isTabletOrMobile }),
      ...CategoriesRoutes({ isTabletOrMobile }),
      ...Cart({ isTabletOrMobile }),
      ...OrderConfirmation({ isTabletOrMobile, isCartEmpty }),
      ...RegistrationRoutes({ isTabletOrMobile }),
      ...LoginRoutes({ isTabletOrMobile }),
      ...ProfileRoutes({ isTabletOrMobile, isLogin }),
      ...OrderRoutes({ isTabletOrMobile, isLogin }),
      ...PrivacyPolicyRoutes({ isTabletOrMobile }),
      ...SalesOrderRoutes({ isTabletOrMobile }),
      ...AddressesRoutes({ isTabletOrMobile }),
      ...CheckoutRoutes({ isTabletOrMobile }),
      {
        path: '/whatsapp/:phone',
        component: WhatsappRedirect,
        layout: ({ children }) => ({ children }),
      },
    ],
    [isTabletOrMobile, isCartEmpty, isLogin]
  );

  return (
    <TrackerProvider>
      <ProductSearchProvider>
        <Switch>
          {routes.map(route => {
            const isPrivateRoute = route.isPrivate || false;

            let MainComponent = route.component;
            const LayoutComponent = route.layout;

            const layoutProps = route.layoutProps || {};

            if (isPrivateRoute && !isLogin) {
              MainComponent = RestrictedPage;
            }

            return (
              <Route
                path={route.path}
                exact={route.isExact || true}
                key={route.path}
              >
                <LayoutComponent {...layoutProps}>
                  <Suspense fallback={<LoadingPageIndicator />}>
                    <MainComponent {...layoutProps} />
                  </Suspense>
                </LayoutComponent>
              </Route>
            );
          })}
        </Switch>
      </ProductSearchProvider>
    </TrackerProvider>
  );
};

export default Routes;
