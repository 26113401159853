import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout as MainLayout } from 'antd';
import MobileNavbar from 'Components/Mobile/Navbar';
import MobileBottomNavbar from 'Components/Mobile/BottomNavbar';
import MobileStickyWhatsappButton from 'Components/StickyWhatsappButton';
import ProductSearchDrawer from 'Components/Mobile/ProductSearchDrawer';

const { Content } = MainLayout;

/**
 * @Note Layout to be used for mobile web pages
 * @returns {React.ElementType}
 */
const HomeLayout = ({
  children,
  shouldDisplayBottomNavbar,
  paddingTop,
  paddingBottom,
  isWhatsappStickyButtonVisible,
}) => {
  const [searchDrawerActive, setSearchDrawerActive] = useState(false);

  return (
    <MainLayout style={{ height: '100%' }}>
      <MobileNavbar
        variant="primary"
        isBackButtonVisible={false}
        isSearchBarVisible
        onInputFocus={() => setSearchDrawerActive(true)}
      />
      <Content
        style={{
          backgroundColor: '#FFFFFF',
          paddingTop,
          paddingBottom,
        }}
      >
        {children}
        {isWhatsappStickyButtonVisible && (
          <MobileStickyWhatsappButton marginBottom={64} />
        )}
      </Content>
      {shouldDisplayBottomNavbar && <MobileBottomNavbar />}

      {/* Search Overlay */}
      <ProductSearchDrawer
        visible={searchDrawerActive}
        onClose={() => setSearchDrawerActive(false)}
      />
    </MainLayout>
  );
};
HomeLayout.propTypes = {
  shouldDisplayBottomNavbar: PropTypes.bool.isRequired,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  isWhatsappStickyButtonVisible: PropTypes.bool,
};
HomeLayout.defaultProps = {
  paddingTop: 0,
  paddingBottom: 64,
  isWhatsappStickyButtonVisible: true,
};

export default HomeLayout;
