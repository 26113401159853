import styled from 'styled-components';
import { COLOR, Z_INDEX } from 'Configurations/Constants/styles';

export const BackgroundOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.NAVBAR - 1};
`;

export const PopoverContainer = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  border-radius: 0 0 8px 8px;
  width: 480px;
  max-height: 400px;
  padding: 24px;
  overflow: auto;

  position: fixed;
  z-index: ${Z_INDEX.NAVBAR};
  top: 64px;
  transform: translateX(-90%);

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${COLOR.GRAY_40};
    border: 3px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
  }
`;
