import { useQuery } from 'react-query';
import api from 'Configurations/Utilities/api';

/**
 * @Desc - Get a single category by ID
 */
const apiGetCategoryById = async ({ queryKey }) => {
  try {
    const [, { productCategoryId }] = queryKey;
    if (productCategoryId) {
      const response = await api.get(`/product/category`, {
        params: { productCategoryId },
      });
      const { data } = response;
      const level1Category = data?.find(
        x => x.id === productCategoryId && x.categoryLevel === '0'
      );
      const level2Categories = data?.filter(x => x.categoryLevel === '1');
      const level3Categories = data?.filter(x => x.categoryLevel === '2');
      const level4Categories = data?.filter(x => x.categoryLevel === '3');
      const level5Categories = data?.filter(x => x.categoryLevel === '4');
      const level6Categories = data?.filter(x => x.categoryLevel === '5');
      const level7Categories = data?.filter(x => x.categoryLevel === '6');
      const level8Categories = data?.filter(x => x.categoryLevel === '7');

      const category = {
        ...level1Category,
        /**
         * We currently have up to 8 level of categories
         */
        subCategories: {
          ...(level2Categories.length ? { level2: level2Categories } : {}),
          ...(level3Categories.length ? { level3: level3Categories } : {}),
          ...(level4Categories.length ? { level4: level4Categories } : {}),
          ...(level5Categories.length ? { level5: level5Categories } : {}),
          ...(level6Categories.length ? { level6: level6Categories } : {}),
          ...(level7Categories.length ? { level7: level7Categories } : {}),
          ...(level8Categories.length ? { level8: level8Categories } : {}),
        },
      };
      return category;
    }
    return null;
  } catch (error) {
    return error;
  }
};
export const useQueryGetCategoryById = ({ productCategoryId = null }) => {
  const {
    isLoading: isGetCategoryByIdLoading,
    isError: isGetCategoryByIdError,
    data: category = null,
    refetch: getCategoryById,
  } = useQuery(['getCategoryById', { productCategoryId }], apiGetCategoryById, {
    refetchOnWindowFocus: false,
  });

  return {
    isGetCategoryByIdLoading,
    isGetCategoryByIdError,
    category,
    getCategoryById,
  };
};

/**
 * Get All Category
 */
export const useQueryGetAllCategory = () => {
  const {
    isLoading,
    isError,
    data: categories = [],
    refetch: refetchGetAllCategories,
  } = useQuery(
    'getCategories',
    () => api.get('/product/get_all_category').then(response => response.data),
    { refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    isError,
    categories,
    refetchGetAllCategories,
  };
};
