import React from 'react';
import { Layout as MainLayout, Image } from 'antd';
import { Link } from 'react-router-dom';
import Logo from 'Static/Logo-white.svg';
import { COLOR } from 'Configurations/Constants/styles';

const { Content, Header } = MainLayout;

const MinimalLayout = ({ children }) => (
  <MainLayout style={{ backgroundColor: '#E5E5E5', height: '100%' }}>
    <Header
      style={{ backgroundColor: COLOR.PRIMARY, padding: '0px 0px 0px 120px' }}
    >
      <Link to="/">
        <Image
          preview={false}
          src={Logo}
          alt="Materee.id"
          height={32}
          width={120}
          style={{ marginBottom: 8, marginTop: 8 }}
        />
      </Link>
    </Header>
    <Content
      style={{
        marginTop: 24,
        marginBottom: 120,
      }}
    >
      {children}
    </Content>
  </MainLayout>
);

export default MinimalLayout;
