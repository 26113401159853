import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import Typography from 'Components/Globals/Typography';

import { StyledNavbarContainer, StyledMenuLink } from './BottomNavbar.style';

const BottomNavbar = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('/');

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <StyledNavbarContainer>
      <Row>
        {/* Home */}
        <Col span={8}>
          <StyledMenuLink isActive={() => currentPath === '/'} to="/">
            <HomeOutlined
              style={{
                fontSize: 14,
                color: currentPath === '/' ? '#f37021' : '#333333',
              }}
            />
            <Typography.Caption12
              style={{
                marginTop: 4,
                fontWeight: currentPath === '/' ? 'bold' : 'regular',
              }}
            >
              Beranda
            </Typography.Caption12>
          </StyledMenuLink>
        </Col>

        {/* Orders */}
        <Col span={8}>
          <StyledMenuLink
            isActive={() => currentPath === '/orders'}
            to="/orders"
          >
            <FileTextOutlined
              style={{
                fontSize: 14,
                color: currentPath === '/orders' ? '#f37021' : '#333333',
              }}
            />
            <Typography.Caption12
              style={{
                marginTop: 4,
                fontWeight: currentPath === '/orders' ? 'bold' : 'regular',
              }}
            >
              Pesanan
            </Typography.Caption12>
          </StyledMenuLink>
        </Col>

        {/* Profile */}
        <Col span={8}>
          <StyledMenuLink
            isActive={() => currentPath === '/profile'}
            to="/profile"
          >
            <UserOutlined
              style={{
                fontSize: 14,
                color: currentPath === '/profile' ? '#f37021' : '#333333',
              }}
            />
            <Typography.Caption12
              style={{
                marginTop: 4,
                fontWeight: currentPath === '/profile' ? 'bold' : 'regular',
              }}
            >
              Profil
            </Typography.Caption12>
          </StyledMenuLink>
        </Col>
      </Row>
    </StyledNavbarContainer>
  );
};

export default BottomNavbar;
