import axios from 'axios';
import { getAuthCookie } from 'Configurations/Utilities/cookiesAuth';

let headers = {};

const authCookie = getAuthCookie();
if (authCookie?.token) {
  headers = {
    ...headers,
    Authorization: `Bearer ${authCookie.token}`,
  };
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'localhost:8080',
  headers: { ...headers },
});

/**
 * @TODO
 * Check if there is auth data in cookie
 * Check token expiration in this interceptor
 * Hit to refresh-token endpoint if the token is expired
 */
// axiosInstance.interceptors.request.use(
//   config => {
//     console.log('from interceptor request', config);
//   },
//   error => Promise.reject(error)
// );

export default axiosInstance;
