import { useContext } from 'react';
import { TrackerContext } from 'Contexts/Tracker';

const useTracker = () => {
  const context = useContext(TrackerContext);
  if (context === undefined) {
    throw new Error('useTracker must be used within TrackerProvider');
  }

  const { trackWhatsappButtonClick, trackSalesOrderPageVisit } = context;

  return {
    trackWhatsappButtonClick,
    trackSalesOrderPageVisit,
  };
};

export default useTracker;
