import { lazy } from 'react';
import { SalesOrderLayout as MobileSalesOrderLayout } from 'Layouts/Mobile';
import { MinimalLayout as DesktopMinimalLayout } from 'Layouts/Desktop';

/**
 * Product routes
 * @param {boolean} params.isTabletOrMobile
 * @returns {object[]}
 */
const SalesOrder = ({ isTabletOrMobile }) => [
  {
    path: '/sales-order/:id',
    component: isTabletOrMobile
      ? lazy(() =>
          import(
            /* webpackChunkName: "page-sales-order-mobile" */ 'Pages/SalesOrder/Mobile'
          )
        )
      : lazy(() =>
          import(
            /* webpackChunkName: "page-sales-order-desktop" */ 'Pages/SalesOrder/Desktop'
          )
        ),
    layout: isTabletOrMobile ? MobileSalesOrderLayout : DesktopMinimalLayout,
  },
];

export default SalesOrder;
