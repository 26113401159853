export const Z_INDEX_MOBILE_NAVBAR = 100;
export const Z_INDEX_MOBILE_STICKY_WHATSAPP_BUTTON = 99;
export const Z_INDEX_MOBILE_STICKY_BOTTOM_ACTION = 97;
export const Z_INDEX_STICKY_WHATSAPP_BUTTON = 101;
export const Z_INDEX_DRAWER = 102;

export const Z_INDEX = {
  NAVBAR: 100,

  MOBILE_SHEET_DIALOG: 102,

  STICKY_ORDER_SUMMARY_BOTTOM: 96,

  PAGE_OVERLAY: 103,
};

export const COLOR = {
  WHITE: '#fff',
  BLACK: '#000',
  BLACK_10: '#E1E1E3',

  GRAY_05: '#F7F7F7',
  GRAY_20: '#F0F0F0',
  GRAY_30: '#E0E0E0',
  GRAY_40: '#DADADA',
  GRAY_60: '#A0A0A0',
  GRAY_80: '#808080',
  GRAY_100: '#333333',

  ORANGE_05: '#FFF4EE',
  ORANGE_20: '#FFE3D2',
  ORANGE_60: '#F37021',

  PRIMARY: '#F37021',
};
