import { lazy } from 'react';

import {
  ProfileLayout,
  CommonLayout as CommonMobileLayout,
} from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

const ProfileRoutes = ({ isTabletOrMobile }) => {
  const layoutProps = {
    profileIndex: {
      desktop: {
        isWhatsappSectionVisible: false,
        isFooterVisible: false,
        isStickyWhatsappButtonVisible: false,
      },
      mobile: {},
    },
    profileEdit: {
      desktop: {
        isWhatsappSectionVisible: false,
        isFooterVisible: false,
        isStickyWhatsappButtonVisible: false,
      },
      mobile: {
        pageName: 'Edit Informasi Akun',
        isWhatsappSectionVisible: false,
        isNavbarCartButtonVisible: false,
        isWhatsappStickyButtonVisible: false,
      },
    },
  };

  return [
    {
      path: '/profile',
      isPrivate: true,
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-profile-index-mobile" */ 'Pages/Profile/Index/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-profile-index-desktop" */ 'Pages/Profile/Index/Desktop'
            )
          ),
      layout: isTabletOrMobile ? ProfileLayout : CommonDesktopLayout,
      layoutProps: {
        ...(isTabletOrMobile
          ? layoutProps.profileIndex.mobile
          : layoutProps.profileIndex.desktop),
      },
    },

    {
      path: '/profile/edit',
      isPrivate: true,
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-profile-edit-mobile" */ 'Pages/Profile/ProfileEdit/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-profile-edit-desktop" */ 'Pages/Profile/ProfileEdit/Desktop'
            )
          ),
      layout: isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout,
      layoutProps: {
        ...(isTabletOrMobile
          ? layoutProps.profileEdit.mobile
          : layoutProps.profileEdit.desktop),
      },
    },
  ];
};

export default ProfileRoutes;
