import React, { createContext, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidV4 } from 'uuid';
import Cookies from 'js-cookie';
import DeviceDetector from 'device-detector-js';

import useDevice from 'Hooks/useDevice';

/**
 * mixpanel should be initiated here
 */
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
  debug: process.env.REACT_APP_ENV !== 'production',
});

const cookieOptions = {
  samesite: 'strict',
  'max-age': '604800', // 7 days (in seconds)
};

const COOKIE_KEY = 'trackid';

/**
 * @returns {string}
 */
const setNewUuidToCookie = () => {
  const newUuid = uuidV4();

  Cookies.set(COOKIE_KEY, newUuid, cookieOptions);

  return newUuid;
};

/**
 * @returns {string}
 */
const getUuidFromCookie = () => {
  const uuid = Cookies.get(COOKIE_KEY);

  return uuid;
};

/**
 * @returns {string}
 */
const getDeviceName = () => {
  const deviceDetector = new DeviceDetector();
  const userAgentInfo = window.navigator.userAgent;

  const device = deviceDetector.parse(userAgentInfo);
  const operatingSystem = device?.os?.name || 'Unknown';

  return operatingSystem;
};

export const TrackerContext = createContext(null);

const TrackerProvider = ({ children }) => {
  const { isTabletOrMobile } = useDevice();

  /**
   * Tracks event to mixpanel dashboard
   * @param {object} param
   * @param {string} param.eventName
   * @param {object} param.payload
   */
  const trackEvent = ({ eventName = '', payload = {} }) => {
    let uuid = getUuidFromCookie();
    if (!uuid) {
      uuid = setNewUuidToCookie();
    }

    const platform = isTabletOrMobile ? 'Web Mobile' : 'Web Desktop';
    const pageUrl = window.location.href;

    const mandatoryPayload = {
      // Device info
      device: `${getDeviceName()} (${platform})`,
      // URL where this event is triggered
      url: pageUrl,
      // unique user id
      userId: uuid,
      // is the event triggered in dev environment?
      dev: process.env.REACT_APP_ENV !== 'production',
    };

    mixpanel.track(eventName, {
      ...mandatoryPayload,
      ...payload,
    });
  };

  /**
   * Specific function to do event tracking for "Whatsapp Button Click" event
   * @param {object} param
   * @param {object} param.payload
   */
  const trackWhatsappButtonClick = ({ location = '' }) => {
    if (!location) {
      console.error('[Tracker Error] - need "location" param');
      return;
    }

    trackEvent({
      eventName: 'Whatsapp Button Click',
      payload: {
        location,
      },
    });
  };

  /**
   * Track order summary page visit
   */
  const trackSalesOrderPageVisit = () => {
    trackEvent({
      eventName: 'Sales Order Page Visit',
    });
  };

  /**
   * Prepare context provider values
   */
  const providerValues = useMemo(
    () => ({
      trackWhatsappButtonClick,
      trackSalesOrderPageVisit,
    }),
    []
  );

  return (
    <TrackerContext.Provider value={providerValues}>
      {children}
    </TrackerContext.Provider>
  );
};

export default TrackerProvider;
