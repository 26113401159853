import { useDispatch, useSelector } from 'react-redux';
import {
  clearAuthCookie,
  setAuthCookie,
} from 'Configurations/Utilities/cookiesAuth';

import { useMutationLogin } from './Api/auth';

const useAuth = () => {
  const { isAuthError, isLoadingAuth, getAuthData } = useMutationLogin();

  const auth = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const login = ({ phoneNumber, password }) => {
    getAuthData(
      { phoneNo: phoneNumber, password },
      {
        onSuccess: data => {
          setAuthCookie(data);
          dispatch.auth.setLogin(data);
        },
      }
    );
  };

  const logout = () => {
    clearAuthCookie();
    dispatch.auth.setLogout();
  };

  return {
    /** Return `true` when user is logged in */
    isLogin: auth.isLogin,

    /** Return authentication data, like username, token, etc */
    authData: auth.data,

    /** Used to hit login api and get the auth data */
    login,

    /** Return `true` when the api login is processing */
    isLoginLoading: isLoadingAuth,

    /** Return `true` when api login is error */
    isLoginError: isAuthError,

    /** Used to clear the login information */
    logout,
  };
};

export default useAuth;
