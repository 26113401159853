import { notification } from 'antd';
import { useQuery, useMutation } from 'react-query';
import api from 'Configurations/Utilities/api';

const CART_BASE_URL = '/cart';

/**
 * Get the cart data with these query function
 * @param {object} param
 * @param {boolean} param.enabled
 */
export const useQueryGetCart = ({ enabled = true }) => {
  const {
    data: cartData,
    isLoading: isCartLoading,
    isError: isCartError,
    refetch: refetchCart,
  } = useQuery({
    enabled,
    queryKey: ['cart'],
    queryFn: async () => api.get(CART_BASE_URL).then(response => response.data),
    onError: error => {
      console.error(error);
      notification.error({
        message: 'Internal Server Error',
      });
    },
  });

  return {
    cartData,
    isCartLoading,
    isCartError,
    refetchCart,
  };
};

/**
 * Use this mutation hook to add or reduce items in the cart
 * @param {object} param
 * @param {function} param.onSuccess
 */
export const useMutationUpdateCart = ({ onSuccess }) => {
  const {
    data: updateCartData,
    isLoading: isUpdateCartLoading,
    isError: isUpdateCartError,
    mutate: updateCartMutation,
  } = useMutation({
    /**
     * @param {object} param
     * @param {number} param.productId
     * @param {number} param.quantity
     * @returns {Promise}
     */
    mutationFn: async ({ productId, quantity }) =>
      api
        .put(CART_BASE_URL, { productId, quantity })
        .then(response => response.data),
    onError: error => {
      console.error(error);
      notification.error({
        message: 'Internal Server Error',
      });
    },
    onSuccess,
  });

  return {
    updateCartData,
    isUpdateCartLoading,
    isUpdateCartError,
    updateCartMutation,
  };
};

/**
 * This mutation will delete user cart, which means all the items in the user's cart will be removed
 * @param {object} param
 * @param {function} param.onSuccess
 */
export const useMutationDeleteCart = ({ onSuccess }) => {
  const {
    data: deleteCartData,
    isLoading: isDeleteCartLoading,
    isError: isDeleteCartError,
    mutate: deleteCartMutation,
  } = useMutation({
    /**
     * @param {object} param
     * @param {number} param.id - Cart item id
     * @returns {Promise}
     */
    mutationFn: async ({ id }) =>
      api.delete(`${CART_BASE_URL}/${id}`).then(response => response.data),
    onError: error => {
      console.error(error);
      notification.error({
        message: 'Internal Server Error',
      });
    },
    onSuccess,
  });

  return {
    deleteCartData,
    isDeleteCartLoading,
    isDeleteCartError,
    deleteCartMutation,
  };
};
