import { lazy } from 'react';

import { CommonLayout as CommonMobileLayout } from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

const Cart = ({ isTabletOrMobile }) => {
  const Layout = isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout;
  const layoutProps = {
    desktop: { isWhatsappSectionVisible: false },
    mobile: {
      pageName: 'Keranjang',
      useBottomGutter: true,
      isWhatsappSectionVisible: false,
      isWhatsappStickyButtonVisible: false,
      isWhatsappButtonVisible: false,
      isNavbarCartButtonVisible: false,
    },
  };

  return [
    {
      path: '/cart',
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-cart-mobile" */ 'Pages/Cart/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-cart-desktop" */ 'Pages/Cart/Desktop'
            )
          ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
  ];
};

export default Cart;
