import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';

import api from 'Configurations/Utilities/api';

export const useMutationLogin = () => {
  const {
    mutate: getAuthData,
    isLoading: isLoadingAuth,
    isError: isAuthError,
  } = useMutation(({ phoneNo, password }) =>
    api.post('/auth/login', { phoneNo, password }).then(res => res.data)
  );

  return {
    /** Get auth data from server */
    getAuthData,

    /** Return `true` when auth data is processing */
    isLoadingAuth,

    /** Return `true` when auth data is failed to retreive  */
    isAuthError,
  };
};

export const useMutationRegisterUser = () => {
  const history = useHistory();
  const {
    mutate: registerUser,
    isLoading: isRegisterLoading,
    isError: isRegisterError,
  } = useMutation(
    async ({ name, phoneNo, password }) => {
      await api.post('/auth/register', {
        name,
        phoneNo,
        password,
      });
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Proses pembuatan akun berhasil.',
          duration: 5,
        });
        history.push('/login');
      },
      onError: () => {
        notification.error({
          message: 'Proses pembuatan akun gagal.',
          duration: 5,
        });
      },
    }
  );

  return {
    registerUser,
    isRegisterLoading,
    isRegisterError,
  };
};
