import { get } from 'lodash-es';

export const numberFormat = (
  x = 0,
  returnZero = true,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) => {
  if (!x && !returnZero) return '';
  return Intl.NumberFormat('id', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(x);
};

export const formatAddress = (address = {}) => {
  const tempDetails = get(address, 'addressDetails') || '';
  const tempKecamatan = get(address, 'kecamatan') || '';
  const tempKota = get(address, 'kota') || '';
  const tempProvinsi = get(address, 'provinsi') || '';
  const tempZipCode = get(address, 'zipCode') || '';
  const fullAddress =
    tempDetails +
    (tempKecamatan ? `, ${tempKecamatan}` : '') +
    (tempKota ? `, ${tempKota}` : '') +
    (tempProvinsi ? `, ${tempProvinsi}` : '') +
    (tempZipCode ? ` ${tempZipCode}` : '');
  return fullAddress;
};

export const mapOrder = (array = [], order = [], key = '') => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
};

export default {};
