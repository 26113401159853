import styled from 'styled-components';
import { WhatsAppOutlined } from '@ant-design/icons';
import { Z_INDEX_STICKY_WHATSAPP_BUTTON } from 'Configurations/Constants/styles';

export const StyledButton = styled.button`
  background-color: #128c55;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 24px;
  margin-bottom: ${props => props.marginBottom}px;
  z-index: ${Z_INDEX_STICKY_WHATSAPP_BUTTON};
`;

export const StyledWhatsappIcon = styled(WhatsAppOutlined)`
  font-size: 24px;
  color: #fff;
`;
