import { lazy } from 'react';

import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

const MobileLayout = ({ children }) => children;

const LoginRoutes = ({ isTabletOrMobile }) => {
  const Layout = isTabletOrMobile ? MobileLayout : CommonDesktopLayout;
  const layoutProps = {
    desktop: {
      isFooterVisible: false,
      isStickyWhatsappButtonVisible: false,
      isLocationPickerVisible: false,
      isSearchFieldVisible: false,
      isNavbarWhatsappButtonVisible: false,
      isNavbarCartButtonVisible: false,
      isLoginButtonVisible: false,
    },
    mobile: {},
  };

  return [
    {
      path: '/login',
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-login-mobile" */ 'Pages/Login/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-login-desktop" */ 'Pages/Login/Desktop'
            )
          ),
      layout: Layout,
      layoutProps: {
        ...(isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop),
      },
    },
  ];
};

export default LoginRoutes;
