const STATE = {
  items: [], // items stored in user's cart
  selectedCartItems: [], // stores selected cart items
};

const cart = {
  state: {
    ...STATE,
  },

  reducers: {
    /**
     * Add a single item into the cart
     * @param {STATE} state
     * @param {object} payload
     * @returns {STATE}
     */
    addToCart(
      state,
      {
        id,
        sku,
        name,
        metricUnit,
        imageUrl,
        categoryId,
        categoryName,
        qty,
        price,
      }
    ) {
      const { items } = state;
      const productInCart = items.find(item => item.id === id);
      const isProductExistInCart = productInCart !== undefined;

      if (isProductExistInCart) {
        // Update the cart
        return {
          ...state,
          items: items.map(item => {
            if (item.id === id) {
              return { ...item, qty: qty || 0 };
            }

            return item;
          }),
        };
      }

      // Add the new product in cart
      return {
        ...state,
        items: [
          ...state.items,
          {
            id,
            sku,
            name,
            metricUnit,
            qty,
            imageUrl,
            categoryId,
            categoryName,
            price,
          },
        ],
      };
    },

    /**
     * Select particular item in the cart
     * @param {STATE} state
     * @param {object} payload
     * @returns {STATE}
     */
    selectCartItem(
      state,
      {
        id,
        sku,
        name,
        metricUnit,
        imageUrl,
        categoryId,
        categoryName,
        qty,
        price,
      }
    ) {
      const { selectedCartItems } = state;
      const newSelectedCartItems = [
        ...selectedCartItems,
        {
          id,
          sku,
          name,
          metricUnit,
          imageUrl,
          categoryId,
          categoryName,
          qty,
          price,
        },
      ];

      return {
        ...state,
        selectedCartItems: [...newSelectedCartItems],
      };
    },

    /**
     * Select all items in the cart
     * @param {STATE} state
     * @returns {STATE}
     */
    selectAllCartItem(state) {
      const { items } = state;

      return {
        ...state,
        selectedCartItems: [...items],
      };
    },

    /**
     * Remove a selected item from the selected cart items array
     * @param {STATE} state
     * @param {number} productId
     * @returns {STATE}
     */
    removeSelectedCartItem(state, productId) {
      const { selectedCartItems } = state;
      const filteredCartItems = selectedCartItems.filter(
        item => item.id !== productId
      );

      return {
        ...state,
        selectedCartItems: [...filteredCartItems],
      };
    },

    /**
     * Remove all selected cart items
     * @param {STATE} state
     * @returns {STATE}
     */
    removeAllSelectedCartItem(state) {
      return {
        ...state,
        selectedCartItems: [],
      };
    },

    /**
     * Remove single item from the cart
     * @param {STATE} state
     * @param {number} productId
     * @returns {STATE}
     */
    removeFromCart(state, productId) {
      const { items } = state;
      const filteredCartItems = items.filter(item => item.id !== productId);

      return {
        ...state,
        items: [...filteredCartItems],
      };
    },

    /**
     * Update an item in the cart
     * This will also update the selected cart item, if the corresponding item is selected
     * use this reducer when the user changes the qty of a cart item
     * @param {STATE} state
     * @param {object} payload
     * @returns {STATE}
     */
    updateCartItem(state, { id, qty }) {
      const { items, selectedCartItems } = state;
      const updatedItems = items.reduce((result, item) => {
        if (item.id === id) {
          return [
            ...result,
            {
              ...item,
              qty,
            },
          ];
        }
        return [...result, item];
      }, []);
      const updatedSelectedCartItems = selectedCartItems.reduce(
        (result, item) => {
          if (item.id === id) {
            return [
              ...result,
              {
                ...item,
                qty,
              },
            ];
          }
          return [...result, item];
        },
        []
      );

      return {
        ...state,
        items: [...updatedItems],
        selectedCartItems: [...updatedSelectedCartItems],
      };
    },

    /**
     * Remove all items from the cart and the selected cart items
     * @param {STATE} state
     * @returns {STATE}
     */
    clearCart(state) {
      return {
        ...state,
        items: [],
        selectedCartItems: [],
      };
    },
  },
};

export default cart;
