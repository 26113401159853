import React from 'react';
import { Layout as MainLayout } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StickyWhatsappButton from 'Components/StickyWhatsappButton';
import Navbar from 'Components/Desktop/Navbar';
import Footer from 'Components/Desktop/Footer';

const { Content } = MainLayout;

const StyledHeaderContentGutter = styled.div`
  height: 64px;
`;

const CommonLayout = ({
  children,
  isStickyWhatsappButtonVisible,
  isFooterVisible,
  isWhatsappSectionVisible,
  isLocationPickerVisible,
  isSearchFieldVisible,
  isNavbarWhatsappButtonVisible,
  isNavbarCartButtonVisible,
  isLoginButtonVisible,
}) => (
  <MainLayout style={{ backgroundColor: 'transparent', height: '100%' }}>
    <Navbar
      isLocationPickerVisible={isLocationPickerVisible}
      isSearchFieldVisible={isSearchFieldVisible}
      isNavbarWhatsappButtonVisible={isNavbarWhatsappButtonVisible}
      isNavbarCartButtonVisible={isNavbarCartButtonVisible}
      isLoginButtonVisible={isLoginButtonVisible}
    />

    <StyledHeaderContentGutter />

    <Content style={{ backgroundColor: '#FFFFFF' }}>{children}</Content>

    {isStickyWhatsappButtonVisible && <StickyWhatsappButton />}

    {isFooterVisible && (
      <Footer isWhatsappSectionVisible={isWhatsappSectionVisible} />
    )}
  </MainLayout>
);

CommonLayout.propTypes = {
  /** When set to `false`, the sticky whatsapp button won't be visible */
  isStickyWhatsappButtonVisible: PropTypes.bool,

  /** When set to `false, the footer will be hidden */
  isFooterVisible: PropTypes.bool,

  /** When set to `false`, the whatsapp section above the footer won't be visible */
  isWhatsappSectionVisible: PropTypes.bool,

  /** When set to `false` will hide location picker on the Navbar */
  isLocationPickerVisible: PropTypes.bool,

  /** When set to `false` will hide search field on the Navbar */
  isSearchFieldVisible: PropTypes.bool,

  /** When set to `false` will hide Whatsapp button on the Navbar */
  isNavbarWhatsappButtonVisible: PropTypes.bool,

  /** When set to `false` will hide Whatsapp button on the Navbar */
  isNavbarCartButtonVisible: PropTypes.bool,

  /** When set to `false` will hide login button on the Navbar */
  isLoginButtonVisible: PropTypes.bool,
};

CommonLayout.defaultProps = {
  isStickyWhatsappButtonVisible: true,
  isFooterVisible: true,
  isWhatsappSectionVisible: true,
  isLocationPickerVisible: true,
  isSearchFieldVisible: true,
  isNavbarWhatsappButtonVisible: false,
  isNavbarCartButtonVisible: true,
  isLoginButtonVisible: true,
};

export default CommonLayout;
