import React, { useEffect, useMemo, useRef } from 'react';
import { Row, Col, Drawer, Form, Input, Spin } from 'antd';
import {
  ArrowLeftOutlined,
  SearchOutlined,
  RightOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { throttle, get } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import api from 'Configurations/Utilities/api';
import Typography from 'Components/Globals/Typography';

import useProductSearch from 'Hooks/useProductSearch';
import useQueryParams from 'Hooks/useQueryParams';

/**
 * Renders Loading Spin or the actual result item
 * @param {object[]} param.searchResults
 * @param {object[]} param.searchResults
 * @returns
 */
const RenderSearchResults = ({ searchResults = [], isLoading = false }) => {
  const history = useHistory();

  if (isLoading) {
    return (
      <Row justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }

  if (searchResults.length) {
    return searchResults.map(result => (
      <Row
        key={`search-result-${result.id}`}
        align="middle"
        onClick={() => history.push(`/products/${result.id}`)}
        style={{ padding: '12px 0', cursor: 'pointer' }}
      >
        <Col flex="24px">
          <SearchOutlined style={{ color: '#808080' }} />
        </Col>
        <Col flex="auto">
          <Typography.Caption12>{result.name}</Typography.Caption12>
        </Col>
        <Col flex="24px">
          <RightOutlined style={{ color: '#808080', fontSize: 12 }} />
        </Col>
      </Row>
    ));
  }

  return null;
};

/**
 * @desc - Main component
 * @returns {React.ElementType}
 */
const ProductSearchDrawer = ({ visible, onClose }) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const [form] = Form.useForm();
  const { searchQuery, setSearchQuery } = useProductSearch();
  const searchField = useRef(null);
  const {
    data: searchResults = [],
    isLoading: isGetProductsLoading = false,
    mutate: getProductsMutation,
  } = useMutation(body => api.post('/products', body).then(res => res.data));

  const throttledGetProductsMutation = useMemo(
    () => throttle(param => getProductsMutation(param), 800),
    []
  );

  /**
   * Invoked when the user press enter on the search field, when the autocomplete result is not showing
   * @param {object} values
   */
  const onFormFinish = values => {
    const q = get(values, 'search-query');
    if (q) {
      /**
       * Searching by navbar should clear all filters
       */
      queryParams.set('q', q);
      queryParams.delete('category');
      queryParams.delete('1');
      queryParams.delete('2');
      queryParams.delete('3');
      queryParams.delete('page');
      history.push(`/products?${queryParams}`);
    }

    /** make sure the drawer is closed again */
    onClose();
  };

  /**
   * Focus on the search text field
   */
  useEffect(() => {
    searchField.current?.focus({
      cursor: 'all',
    });
  }, [visible, searchField]);

  /**
   * Search Products Effect
   * Invoked everytime user inputs a text on the field
   */
  useEffect(() => {
    form.setFieldsValue({ 'search-query': searchQuery });

    if (searchQuery) {
      throttledGetProductsMutation({
        nameLike: searchQuery,
      });
    }
  }, [searchQuery]);

  /**
   * Cleanup throttle
   */
  useEffect(() => {
    throttledGetProductsMutation.cancel();
  }, []);

  return (
    <Drawer
      title={
        <Form form={form} onFinish={onFormFinish}>
          <Form.Item name="search-query" style={{ marginBottom: 0 }}>
            <Input
              ref={searchField}
              placeholder="Cari produk material"
              suffix={
                searchQuery ? (
                  <CloseOutlined
                    style={{ fontSize: 16, color: '#d9d9d9' }}
                    onClick={() => setSearchQuery('')}
                  />
                ) : (
                  <SearchOutlined style={{ fontSize: 16, color: '#d9d9d9' }} />
                )
              }
              onChange={evt => setSearchQuery(evt.target.value)}
            />
          </Form.Item>
        </Form>
      }
      visible={visible}
      closeIcon={<ArrowLeftOutlined />}
      onClose={onClose}
      placement="bottom"
      height="100%"
      headerStyle={{ paddingLeft: 16, paddingRight: 16 }}
      bodyStyle={{ paddingLeft: 16, paddingRight: 16, paddingTop: 12 }}
    >
      <RenderSearchResults
        isLoading={isGetProductsLoading}
        searchResults={searchResults}
      />
    </Drawer>
  );
};

export default ProductSearchDrawer;
