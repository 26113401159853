import React, { createContext, useState, useMemo, useEffect } from 'react';
import useQueryParams from 'Hooks/useQueryParams';

export const ProductSearchContext = createContext(null);

const ProductSearchProvider = ({ children }) => {
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    category: null,
    subCategories: null,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    category: null,
    subCategories: null,
  });
  const [activeFilter, setActiveFilter] = useState(''); // possible values: 'category' | 'subCategory'

  /**
   * Populates filter data for users to select
   * @param {string} param.filterType - 'category' | 'subCategory'
   * @param {object | object[]} param.value - filter value to be populated
   */
  const populateFilters = ({ filterType = '', value = null }) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value,
    }));
  };

  /**
   * Un-Populates filter data
   * @param {string} param.filterType - 'category' | 'subCategory'
   */
  const unpopulateFilters = ({ filterType = '' }) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: null,
    }));
  };

  /**
   * Applies filter for searching
   * @param {string} param.filterType - 'category' | 'subCategory'
   * @param {object | object[]} param.value - filter value to be assigned
   */
  const applyFilter = ({ filterType = '', value = null }) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterType]: value,
    }));
  };

  /**
   * Unappllies filter for searching
   * @param {string} param.filterType - 'category' | 'subCategory'
   */
  const unapplyFilter = ({ filterType = '' }) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterType]: null,
    }));
  };

  /**
   * Toggle show/hide of filter drawers
   * @param {string} param.filterType - 'category' | 'subCategory'
   */
  const toggleFilterDrawer = ({ filterType = '' }) => {
    setActiveFilter(filterType);
  };

  /**
   * @effect
   * Handle states when pages are reloaded
   * Make sure that searchQuery is populated by 'q' query param from URL
   */
  useEffect(() => {
    /** Search Query */
    if (queryParams.get('q')) {
      setSearchQuery(queryParams.get('q'));
    }
  }, [queryParams]);

  /**
   * Prepare provider values
   */
  const providerValues = useMemo(
    () => ({
      // Search query, text typed by user in searchbar
      searchQuery,
      setSearchQuery,

      // consist of selected filters
      filters,
      populateFilters,
      unpopulateFilters,
      selectedFilters,
      applyFilter,
      unapplyFilter,

      // Filter drawer states and methos
      activeFilter,
      toggleFilterDrawer,
    }),
    [searchQuery, filters, selectedFilters, activeFilter]
  );

  return (
    <ProductSearchContext.Provider value={providerValues}>
      {children}
    </ProductSearchContext.Provider>
  );
};

export default ProductSearchProvider;
