/* eslint-disable */
import React from 'react';
import { useParams } from 'react-router-dom';

const WhatsappRedirect = () => {
  const { phone } = useParams();
  window.location.href = `https://wa.me/${phone}`;
  return null;
};

export default WhatsappRedirect;
