import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { notification } from 'antd';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'Store';
import Routes from './Configurations/Routes/index';

const persistor = getPersistor();

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        retry: false,
        onError: err =>
          notification.error({
            description: err.message,
            duration: 3,
          }),
      },
    },
  });
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes />
          </Router>
        </QueryClientProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default App;
