import React, { useMemo } from 'react';
import { groupBy } from 'lodash-es';
import { Result, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import contactWhatsapp from 'Configurations/Utilities/contactWhatsapp';
import { COSTUMER_SERVICE_WHATSAPP } from 'Configurations/Constants/config';

const useCart = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /** Return all cart data */
  const cartData = useSelector(state => state.cart.items);

  /** Return all selected cart items */
  const selectedCartItems = useSelector(state => state.cart.selectedCartItems);

  /**
   * Return all cart data, but the products will be grouped by their category
   * @TODO - delete this when the new cart page for MWEB and DWEB is done
   * @returns {object[]}
   */
  const cartDataGroupedByCategory = useMemo(
    () => groupBy(cartData, 'categoryId'),
    [cartData]
  );

  /**
   * @returns {boolean}
   */
  const isCartEmpty = useMemo(() => cartData?.length === 0, [cartData]);

  /**
   * Return the total products in cart. Product with zero qty will be excluded
   * @returns {number}
   */
  const totalProductsInCart = useMemo(
    () =>
      cartData?.reduce((total, product) => {
        if (product.qty > 0) {
          return total + 1;
        }

        return total;
      }, 0),
    [cartData]
  );

  /**
   * Some products will have 0 qty. Use this function to clear them
   */
  const clearZeroQtyProductsFromCart = () => {
    cartData.forEach(item => {
      if (item.qty === 0) {
        dispatch.cart.removeFromCart(item.id);
      }
    });
  };

  const addToCart = product => {
    dispatch.cart.addToCart(product);
  };

  /**
   * Select any single cart item the user wants to checkout
   * @param {object} product
   */
  const selectCartItem = product => {
    dispatch.cart.selectCartItem(product);
  };

  /**
   * Select all cart item to be checkout
   */
  const selectAllCartItem = () => {
    dispatch.cart.selectAllCartItem();
  };

  /**
   * Update single item in the cart and the selected cart items
   * @param {object} param
   * @param {number} param.productId
   * @param {number} param.qty
   */
  const updateCartItem = ({ productId, qty }) => {
    dispatch.cart.updateCartItem({ id: productId, qty });
  };

  /**
   * Remove a selected item from the selected cart items array
   * @param {number} productId
   */
  const removeSelectedCartItem = productId => {
    dispatch.cart.removeSelectedCartItem(productId);
  };

  /**
   * Remove all selected cart items
   */
  const removeAllSelectedCartItem = () => {
    dispatch.cart.removeAllSelectedCartItem();
  };

  /**
   * Remove single item from the cart
   * @param {number} productId
   */
  const removeFromCart = productId => {
    dispatch.cart.removeFromCart(productId);
  };

  /**
   * Remove all items from the cart and the selected cart items
   */
  const clearCart = () => {
    dispatch.cart.clearCart();
  };

  /** Will open Whatsapp and contains products on cart when gets executed */
  const openWhatsapp = () => {
    const cartProductsAsText = cartData
      .map(item => `${item.qty} ${item.name}`)
      .join('\n- ');

    const whatsappMessage = `Halo Tim Materee, saya ingin pesan produk berikut\n- ${cartProductsAsText}`;

    contactWhatsapp(COSTUMER_SERVICE_WHATSAPP, whatsappMessage);
  };

  /** Cart Page Wrapper. This can be used to optionally render your component based on cart data */
  let CartPageWrapper = ({ children }) => <div>{children}</div>;

  if (isCartEmpty) {
    CartPageWrapper = () => (
      <Result
        style={{ marginTop: 28 }}
        status="404"
        title="Keranjang belanjamu kosong"
        extra={
          <Button type="primary" onClick={() => history.push('/products')}>
            Belanja Sekarang
          </Button>
        }
      />
    );
  }

  return {
    cartData,
    selectedCartItems,
    cartDataGroupedByCategory,
    isCartEmpty,
    totalProductsInCart,

    addToCart,
    selectCartItem,
    selectAllCartItem,
    updateCartItem,
    removeSelectedCartItem,
    removeAllSelectedCartItem,
    removeFromCart,
    clearCart,
    clearZeroQtyProductsFromCart,
    openWhatsapp,

    CartPageWrapper,
  };
};

export default useCart;
