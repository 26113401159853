import { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { get } from 'lodash-es';
import useAuth from 'Hooks/useAuth';

import api from 'Configurations/Utilities/api';

export const useQueryProduct = (queryParams = {}, queryKey = 'getProducts') => {
  const [errorStatus, setErrorStatus] = useState(null);
  const { logout } = useAuth();
  const { pageNo = 0, pageSize = 25, skuList = [] } = queryParams;

  const skuListSeparatedInComma = skuList.join(',');

  const {
    data,
    isFetching = false,
    isError,
  } = useQuery(queryKey, () =>
    api
      .get(
        `/product?pageNo=${pageNo}&pageSize=${pageSize}&skuList=${skuListSeparatedInComma}`
      )
      .then(res => res.data)
      .catch(err => {
        const status = get(err, 'response.status', 500);
        setErrorStatus(status);
      })
  );

  useEffect(() => {
    if (errorStatus === 401) {
      logout();
      window.location.reload(false);
    }
    setErrorStatus(null);
  }, [errorStatus]);

  return {
    /** The products data */
    products: data?.content || [],

    /** Product is loading */
    isLoadingProducts: isFetching,

    /** Return true when something wrong happen */
    isProductsError: isError,
  };
};

export const useQueryProductDetail = slug => {
  const {
    data,
    isFetching = false,
    isError,
    refetch,
  } = useQuery('productDetail', () =>
    api.get(`/product/${slug}`).then(res => res.data)
  );

  useEffect(() => {
    refetch();
  }, [slug]);

  return {
    /** The product data */
    product: data,

    /** Product is loading */
    isLoadingProduct: isFetching,

    /** Return true when something wrong happen */
    isProductError: isError,
  };
};

export const useQueryRecommendedProduct = () => {
  const {
    data,
    isFetching = false,
    isError,
  } = useQuery('recommendedProducts', () =>
    api.get('/product/get_recommended_product').then(res => res.data)
  );

  return {
    /** The recommended products */
    recommendedProducts: data,

    /** Product is loading */
    isLoadingRecommendation: isFetching,

    /** Return true when something wrong happen */
    isRecommendationError: isError,
  };
};

export const useQuerySimilarProducts = (
  queryParams = {},
  queryKey = 'similarProducts'
) => {
  const { productId = 0, productCount = 6 } = queryParams;

  const {
    data,
    isFetching = false,
    isError,
  } = useQuery(queryKey, () =>
    api
      .get(
        `/product/similar_by_product?itemSize=${productCount}&productId=${productId}`
      )
      .then(res => res.data)
  );

  return {
    /** The similar products */
    similarProducts: data || [],

    /** Products is loading */
    isLoadingSimilarProducts: isFetching,

    /** Return true when something wrong happen */
    isSimilarProductsError: isError,
  };
};

export const useMutationGetProductsByCategory = () => {
  const {
    data: products = [],
    mutate: getProductsByCategory,
    isLoading: isGetProductsByCategoryLoading,
  } = useMutation(body =>
    api.post('/product/get_item_by_category', body).then(res => res.data)
  );

  return {
    /** products data */
    products,

    /** mutation method */
    getProductsByCategory,

    /** Loading state */
    isGetProductsByCategoryLoading,
  };
};

/**
 * Search products by name
 * Used in Navbar's search field
 * @ref - https://materee-dev1-dot-materee-production.et.r.appspot.com/swagger-ui/index.html#/product-controller/findByNameUsingGET_1
 */
const apiGetProductsByName = async ({ queryKey }) => {
  try {
    const [, { searchQuery }] = queryKey;
    if (searchQuery) {
      const urlSearchParams = new URLSearchParams({
        searchParameter: searchQuery,
      });
      const response = await api.get(
        `/product/find_by_name${urlSearchParams ? `?${urlSearchParams}` : ''}`
      );
      const { data } = response;
      return data;
    }
    return [];
  } catch (error) {
    return error;
  }
};
export const useQueryGetProductsByName = ({ searchQuery = '' }) => {
  const {
    data: products = [],
    isLoading: isGetProductsByNameLoading,
    refetch: getProductsByName,
  } = useQuery(['productSearchByName', { searchQuery }], apiGetProductsByName, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    products,
    isGetProductsByNameLoading,
    getProductsByName,
  };
};

/**
 * Get product list by name or filters
 * @TODO use this in ProductList page (desktop/mobile)
 */
export const useMutationGetProducts = () => {
  /**
   * Request body should be like
   * {
      "categoryFilter": {
        "additionalProp1": 0,
        "additionalProp2": 0,
        "additionalProp3": 0
      },
      "multipleCategoryFilter": {
        "additionalProp1": [0, ...],
        "additionalProp2": [0, ...],
        "additionalProp3": [0, ...],
      },
      "nameLike": "string"
    }
   */
  const {
    data: products = [],
    mutate: getProducts,
    isLoading: isGetProductsLoading,
  } = useMutation(body =>
    api.post('/products', body).then(response => response.data)
  );

  return {
    products,
    getProducts,
    isGetProductsLoading,
  };
};
