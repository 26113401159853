import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { get } from 'lodash-es';
import { Row, Col, Divider, Image } from 'antd';

import { COLOR } from 'Configurations/Constants/styles';
import { numberFormat } from 'Configurations/Utilities/formatter';
import Typography from 'Components/Globals/Typography';
import useCartNew from 'Hooks/useCartNew';

import CartEmptyStateIllustration from 'Static/cart-empty-state.png';

import { BackgroundOverlay, PopoverContainer } from './CartPopover.style';

/**
 * Will be shown if the user has no items in their cart
 */
const EmptyState = () => (
  <Row justify="center" align="center" style={{ marginBottom: 32 }}>
    <Divider style={{ borderColor: COLOR.GRAY_40 }} />
    <Col style={{ marginTop: 16, marginBottom: 8 }}>
      <Image src={CartEmptyStateIllustration} preview={false} />
    </Col>
    <Col>
      <Typography.Body16 style={{ fontWeight: 'bold', marginBottom: 16 }}>
        Keranjang belanja kamu masih kosong
      </Typography.Body16>
    </Col>
    <Col>
      <Typography.Body14
        style={{ color: COLOR.GRAY_80, textAlign: 'center', padding: '0 24px' }}
      >
        Produk yang kamu beli akan ditampilkan di keranjang ini. Lihat-lihat dan
        tambahkan produk sekarang juga!
      </Typography.Body14>
    </Col>
  </Row>
);

/**
 * This component displays the details of each products in the user's cart
 */
const CartItem = ({ item }) => {
  const actualProductData = get(item, 'productResponse', {});

  /**
   * @returns {object}
   */
  const { name, img, price } = useMemo(
    () => ({
      name: get(actualProductData, 'name', ''),
      img: get(actualProductData, 'smallSizeUrl', ''),
      price: get(actualProductData, 'price', 0),
    }),
    [actualProductData]
  );

  return (
    <Row
      gutter={16}
      justify="space-between"
      align="middle"
      style={{ padding: 0 }}
    >
      <Divider style={{ borderColor: COLOR.GRAY_40, marginBottom: 16 }} />
      <Col flex="64px">
        <Image src={img} preview={false} />
      </Col>
      <Col flex="200px">
        <Typography.Body16>{name}</Typography.Body16>
      </Col>
      <Col flex={1} style={{ textAlign: 'right' }}>
        <Typography.Body16 fontWeight="bold">
          {numberFormat(price, true, 0, 0)}
        </Typography.Body16>
      </Col>
    </Row>
  );
};

/**
 * Main component
 */
const CartPopover = ({ onMouseEnter, onMouseLeave }) => {
  const { totalProductsInCart, cartData } = useCartNew();

  /**
   * @returns {HTMLElement}
   */
  const overlayMountPoint = useMemo(
    () => document.querySelector('.ant-layout'),
    []
  );

  /**
   * @returns {boolean}
   */
  const shouldDisplayCartItems = useMemo(
    () => get(cartData, 'length') > 0,
    [cartData]
  );

  return (
    <>
      {createPortal(<BackgroundOverlay />, overlayMountPoint)}
      <PopoverContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Subheading20 style={{ fontWeight: 'bold' }}>
              Keranjang ({totalProductsInCart} Produk)
            </Typography.Subheading20>
          </Col>
          <Col>
            <Link to="/cart">
              <Typography.Body16 style={{ fontWeight: 'bold' }}>
                Lihat Semua
              </Typography.Body16>
            </Link>
          </Col>

          {shouldDisplayCartItems ? (
            cartData.map(item => <CartItem item={item} key={get(item, 'id')} />)
          ) : (
            <EmptyState />
          )}
        </Row>
      </PopoverContainer>
    </>
  );
};

export default CartPopover;
