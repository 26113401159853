import React, { useState } from 'react';
import { Row, Space } from 'antd';

import Navbar from 'Components/Mobile/Navbar';
import ProductSearchDrawer from 'Components/Mobile/ProductSearchDrawer';

import useProductSearch from 'Hooks/useProductSearch';

import {
  StyledFilterItem,
  StyledFilterValueText,
  StyledDownOutlined,
} from './ProductListLayout.style';

/**
 * Sliding filter button section on the navbar
 * @returns {React.ElementType}
 */
const SearchFilters = () => {
  const { toggleFilterDrawer, selectedFilters } = useProductSearch();

  return (
    <Row wrap={false} style={{ width: '100%', padding: '12px 0' }}>
      {/* Category Filter Button */}
      <StyledFilterItem
        variant="product-category"
        selected={!!selectedFilters.category}
        onClick={() => toggleFilterDrawer({ filterType: 'category' })}
      >
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <StyledFilterValueText selected={!!selectedFilters.category?.name}>
            Kategori:{' '}
            {selectedFilters.category && (
              <span style={{ fontWeight: 'bold' }}>{selectedFilters.category.name}</span>
            )}
          </StyledFilterValueText>
          <StyledDownOutlined selected={!!selectedFilters.category?.name} />
        </Space>
      </StyledFilterItem>
    </Row>
  );
};

/**
 * Main component
 */
const ProductListLayout = ({ children }) => {
  const [searchDrawerActive, setSearchDrawerActive] = useState(false);

  return (
    <>
      <Navbar
        variant="secondary"
        isBackButtonVisible
        isSearchBarVisible
        onInputFocus={() => setSearchDrawerActive(true)}
        bottomComponent={<SearchFilters />}
      />

      {children}

      {/* Search Overlay */}
      <ProductSearchDrawer
        visible={searchDrawerActive}
        onClose={() => setSearchDrawerActive(false)}
      />
    </>
  );
};

export default ProductListLayout;
