import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import Typography from 'Components/Globals/Typography';

const IndicatorIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const LoadingPageIndicator = () => (
  <Spin
    indicator={IndicatorIcon}
    size="large"
    tip={
      <Typography.Body14 style={{ marginTop: 12 }}>
        Loading...
      </Typography.Body14>
    }
    style={{ zIndex: 0 }}
  >
    <div style={{ width: '100%', margin: '64px 0', height: 100 }} />
  </Spin>
);

export default LoadingPageIndicator;
