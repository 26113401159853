import styled from 'styled-components';

export const StyledWhatsappSection = styled.div`
  background-color: white;
  padding: 16px;
`;

export const StyledWhatsappSectionContent = styled.div`
  text-align: left;
`;
