import { lazy } from 'react';
import { CommonLayout as CommonMobileLayout } from 'Layouts/Mobile';
import { CommonLayout as CommonDesktopLayout } from 'Layouts/Desktop';

const CheckoutRoutes = ({ isTabletOrMobile }) => {
  const layoutProps = {
    mobile: {
      pageName: 'Checkout',
      isWhatsappSectionVisible: false,
      isNavbarCartButtonVisible: false,
      isWhatsappStickyButtonVisible: false,
    },
    desktop: {
      isStickyWhatsappButtonVisible: false,
      isFooterVisible: false,
      isWhatsappSectionVisible: false,
      isLocationPickerVisible: false,
      isSearchFieldVisible: false,
      isNavbarWhatsappButtonVisible: false,
      isNavbarCartButtonVisible: false,
      isLoginButtonVisible: false,
    },
  };

  return [
    {
      path: '/checkout',
      isPrivate: true,
      component: isTabletOrMobile
        ? lazy(() =>
            import(
              /* webpackChunkName: "page-checkout-details-mobile" */ 'Pages/Checkout/CheckoutDetails/Mobile'
            )
          )
        : lazy(() =>
            import(
              /* webpackChunkName: "page-checkout-details-desktop" */ 'Pages/Checkout/CheckoutDetails/Desktop'
            )
          ),
      layout: isTabletOrMobile ? CommonMobileLayout : CommonDesktopLayout,
      layoutProps: isTabletOrMobile ? layoutProps.mobile : layoutProps.desktop,
    },
  ];
};

export default CheckoutRoutes;
